<template>
  <div :class="isMobile ? 'container' : ''">  
       <b-row class="justify-content-center"> 
         <b-col cols="10" md="10" :style="isMobile ? '' : 'margin-top: -50px;'"  v-if="convencion == 0 &&  video == 0">
          <center> <img src="elementos/jonrronero.png" class="rounded" alt="Image" :width="isMobile ? 220 : '330'"></center> 
          <b-row class="justify-content-center mt-5" > 
                  <b-col cols="10" md="9" lg="4" xl="4" class="mt-5">
                    <center><img src="elementos/opinion.png" class="rounded" alt="Image" :style="isMobile ? 'width:100%;' : 'width:60%; cursor:pointer;'" @click="cambiar()"> </center>
                     
                  </b-col>
                  <b-col cols="12"></b-col>
                  <b-col cols="10" md="9" lg="4" xl="4" class="mt-3">
                    <br v-if="isMobile">
                    <center><img src="elementos/descargarfoto.png" class="rounded" alt="Image" :style="isMobile ? 'width:100%;' : 'width:60%; cursor:pointer;'" @click="descargaFoto()"> </center> 
                  </b-col> 
                  <b-col cols="12"></b-col>
                  <b-col cols="10" md="9" lg="4" xl="4" class="mt-3"> 
                    <br v-if="isMobile">
                    <center> <img src="elementos/vervideo.png" class="rounded" alt="Image" :style="isMobile ? 'width:100%;' : 'width:60%; cursor:pointer;'" @click="verVideo()"> </center> 
                  </b-col> 
              </b-row> 
         </b-col> 
         <!-- <b-col cols="10" md="11" sm="11"  lg="7" xl="7"  v-if="convencion == 0 &&  video == 0">
            <center>
              <br v-if="isMobile"> 
               <b-row>
                <b-col cols="11" md="6" :style="isMobile ? 'margin-left:10px;' : ''">
                  <center><img src="encuesta/post.png" class="rounded" alt="Image" :width="isMobile ? 280 : ''" >  </center> 
                </b-col>
                <b-col cols="11" md="6" :style="isMobile ? 'margin-left:20px;' : ''">
                 <template v-if="!isMobile"><br><br><br><br><br><br><br></template>
                 <b-button  type="button" variant="primary" class="mt-4" 
                  :style="isMobile ? 'background: #5cb615; width: 80%; border-color: #5cb615; border-radius: 15px;' : 'background: #5cb615;  border-color: #5cb615; width: 70%;  border-radius: 15px; height:8%;'"
                  @click="cambiar()">
                    Compártenos tu opinión
                  </b-button> 
                  <br>
                 <b-button  type="button" variant="primary"  class="mt-4" 
                  :style="isMobile ? 'background: #184b6c; width: 80%; border-color: #184b6c; border-radius: 15px;' : 'background: #184b6c;  border-color: #184b6c; width: 70%;  border-radius: 15px;'"
                  @click="descargaFoto()">
                      Descarga la foto <img src="encuesta/descarga.png" class="rounded" alt="Image" :width="isMobile ? 20 : 28" >
                  </b-button>
                  <br>
                  <b-button  type="button" variant="primary" class="mt-4" 
                  :style="isMobile ? 'background: #184b6c; width: 80%; border-color: #184b6c; border-radius: 15px;' : 'background: #184b6c;  border-color: #184b6c; width: 70%;  border-radius: 15px;'"
                  @click="verVideo()">
                      Ver video <img src="encuesta/play.png" class="rounded" alt="Image" :width="isMobile ? 20 : 28" >
                  </b-button> 
                  
                </b-col>
               </b-row>
            </center>
         </b-col>  -->
         <b-col cols="11"  sm="11"  md="11" lg="6" xl="6" v-else-if="convencion == 1 &&  video == 0" style="margin-top: -50px;"> 
          <b-form  @submit="store">
            <br v-if="isMobile"> 
            <center> <img src="elementos/jonrronero.png" class="rounded" alt="Image" :width="isMobile ? 220 : '200'"></center> 
            <h6 class="text-white text-center mt-3" >Gracias por formar parte de la Convención Jonroneros Movistar 2025, tu opinión es importante para seguir mejorando.</h6>
            <div class="mt-4" style="box-shadow: 5px 5px 15px gray; background: #fff;">
               <br>
               <center><img src="encuesta/titulo2.png" class="rounded" alt="Image" :width="isMobile ? 300 : ''"></center>
               <br v-if="!isMobile"> 
                <b-row class="justify-content-center" style="font-size:17px;"> 
                    <b-col cols="11" md="11">
                        <!-- <b  style="font-size:15px;">Queremos que cada vez sea mejor, compártenos tu opinión sobre la Convención Comercial 2024</b> -->
                        <b-row class="mt-4">
                          <b-col cols="11" :md="isMobile ? 2 : 1" class="mt-2"><label for="">Nombre</label></b-col>
                          <b-col cols="11" md="6" :style="!isMobile ? 'margin-left:15px;' : ''"> 
                            <!-- <label for="">Nombre</label> -->
                            <b-form-input type="text" style="border-radius: 10px;" v-model="nombre"/>
                            <p class="text-danger" v-if="errors.includes( 'nombre' )"  ><b>Campo requerido</b></p>
                          </b-col>
                          <b-col cols="11" md="1" :class="isMobile ? 'mt-5' : 'mt-2'"><label for="">Canal</label></b-col>
                          <b-col cols="11" md="3"> 
                          <!-- <label for="">Canal</label> -->
                           <select class="form-control text-center" v-model="canal"  style="border-radius: 10px;">
                              <option value="0" selected>Selecciona</option>
                              <option value="Retail">Retail</option>
                              <option value="TLMKT">TLMKT</option>
                              <option value="Propio">Propio</option>
                              <option value="Especialista">Especialista</option>
                              <option value="Corporativo/Staff">Corporativo/Staff</option>
                            </select>
                            <p class="text-danger" v-if="errors.includes( 'canal' )"  ><b>Campo requerido</b></p>
                          </b-col>
                        </b-row>
                        <br> <br>
                        <b :class="isMobile ? '' : 'mt-1'" style="font-size:17px;">
                          Responde la encuesta con esta escala:
                        </b>
                        <br> 
                        <img :src="isMobile ? 'encuesta/emojim.png' : 'encuesta/linea.png'" class="rounded mt-3" alt="Image"  :style="!isMobile ? 'margin-left:-15px;' : ''">
                        <!-- <p class="mt-5"><b>Viaje</b>, que te pareció la logística para recibir tus boletos y los transportes hotel-aeropuerto.</p>
                        <p class="text-danger" v-if="errors.includes( 'fila1' )"  ><b>Campo requerido</b></p>
                          <img :src="'encuesta/1' + img1fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(1)">
                          <img :src="'encuesta/2' + img2fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(2)">
                          <img :src="'encuesta/3' + img3fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(3)">
                          <img :src="'encuesta/4' + img4fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(4)">
                          <img :src="'encuesta/5' + img5fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(5)">  
                        <p class="mt-5"><b>Hospedaje</b>, qué tal fue la estadía en el Hard Rock hotel, así como los alimentos y bebidas.</p>
                        <p class="text-danger" v-if="errors.includes( 'fila2' )"  ><b>Campo requerido</b></p>
                          <img :src="'encuesta/1' + img1fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(1)">
                          <img :src="'encuesta/2' + img2fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(2)">
                          <img :src="'encuesta/3' + img3fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(3)">
                          <img :src="'encuesta/4' + img4fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(4)">
                          <img :src="'encuesta/5' + img5fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(5)">  
                        <br><br> -->
                        <!-- <p><b class="mt-5">Organización del evento</b>, qué tan satisfecho quedaste con la planeación y experiencia de: </p> -->
                         <br><br> 
                          <b-row class="justify-content-center" style="margin-top:-15px;"> 
                             <b-col cols="12" md="6">
                              <p class="mt-5"><b>Evento completo de principio a fin</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila1' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(1)">
                                    <img :src="'encuesta/2' + img2fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(2)">
                                    <img :src="'encuesta/3' + img3fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(3)">
                                    <img :src="'encuesta/4' + img4fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(4)">
                                    <img :src="'encuesta/5' + img5fila1 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila1(5)"> 
                             </b-col>
                            <b-col cols="12" md="6">
                              <p class="mt-5"><b>Logística de vuelos y aerolíneas</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila2' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(1)">
                                    <img :src="'encuesta/2' + img2fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(2)">
                                    <img :src="'encuesta/3' + img3fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(3)">
                                    <img :src="'encuesta/4' + img4fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(4)">
                                    <img :src="'encuesta/5' + img5fila2 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila2(5)"> 
                             </b-col>
                            <b-col cols="12" md="6">
                              <p class="mt-5"><b>Hospedaje en el Paradius, Los Cabos</b></p>
                                <p class="text-danger" v-if="errors.includes( 'fila3' )"  ><b>Campo requerido</b></p>
                                  <img :src="'encuesta/1' + img1fila3 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila3(1)">
                                  <img :src="'encuesta/2' + img2fila3 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila3(2)">
                                  <img :src="'encuesta/3' + img3fila3 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila3(3)">
                                  <img :src="'encuesta/4' + img4fila3 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila3(4)">
                                  <img :src="'encuesta/5' + img5fila3 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila3(5)"> 
                             </b-col>
                            <b-col cols="12" md="6">
                              <p class="mt-5"><b>Alimentos y bebidas en general</b></p>
                                <p class="text-danger" v-if="errors.includes( 'fila4' )"  ><b>Campo requerido</b></p>
                                <img :src="'encuesta/1' + img1fila4 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila4(1)">
                                <img :src="'encuesta/2' + img2fila4 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila4(2)">
                                <img :src="'encuesta/3' + img3fila4 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila4(3)">
                                <img :src="'encuesta/4' + img4fila4 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila4(4)">
                                <img :src="'encuesta/5' + img5fila4 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila4(5)"> 
                             </b-col>
                            <b-col cols="12" md="6">
                                <p class="mt-5"><b>Coctel de bienvenida</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila5' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila5 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila5(1)">
                                    <img :src="'encuesta/2' + img2fila5 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila5(2)">
                                    <img :src="'encuesta/3' + img3fila5 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila5(3)">
                                    <img :src="'encuesta/4' + img4fila5 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila5(4)">
                                    <img :src="'encuesta/5' + img5fila5 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila5(5)"> 
                             </b-col>
                            <b-col cols="12" md="6">
                              <p class="mt-5"><b>Sesiones Plenarias</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila6' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila6 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila6(1)">
                                    <img :src="'encuesta/2' + img2fila6 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila6(2)">
                                    <img :src="'encuesta/3' + img3fila6 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila6(3)">
                                    <img :src="'encuesta/4' + img4fila6 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila6(4)">
                                    <img :src="'encuesta/5' + img5fila6 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila6(5)"> 
                             </b-col>
                             <!-- <b-col cols="6"> 
                              <p class="mt-5">•	Cena Clausura</p>
                                <p class="text-danger" v-if="errors.includes( 'fila9' )"  ><b>Campo requerido</b></p>
                                  <img :src="'encuesta/1' + img1fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(1)">
                                  <img :src="'encuesta/2' + img2fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(2)">
                                  <img :src="'encuesta/3' + img3fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(3)">
                                  <img :src="'encuesta/4' + img4fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(4)">
                                  <img :src="'encuesta/5' + img5fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(5)"> 
                             </b-col>
                             <b-col cols="6">
                              <p class="mt-5">•	Show comediante - Ojitos de huevo</p>
                                <p class="text-danger" v-if="errors.includes( 'fila10' )"  ><b>Campo requerido</b></p>
                                  <img :src="'encuesta/1' + img1fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(1)">
                                  <img :src="'encuesta/2' + img2fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(2)">
                                  <img :src="'encuesta/3' + img3fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(3)">
                                  <img :src="'encuesta/4' + img4fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(4)">
                                  <img :src="'encuesta/5' + img5fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(5)"> 
                             </b-col>  -->
                          </b-row> 
                        <br> 
                        <b>Destacando la información de sesiones plenarias:</b>
                        <p class="mt-2" >¿Te quedó clara cuál es la visión de negocio a poner en marcha? ¿Cuál?</p>
                          <b-form-input type="text" style="border-radius: 10px; width: 60%; margin-top:-10px;" v-model="pregunta1"/>
                          <p class="text-danger" v-if="errors.includes( 'pregunta1' )"  ><b>Campo requerido</b></p>
                        <p class="mt-2" >¿Cuáles consideras que son las prioridades en las que debemos poner foco?</p>
                          <b-form-input type="text" style="border-radius: 10px; width: 60%; margin-top:-10px;" v-model="pregunta2"/>
                          <p class="text-danger" v-if="errors.includes( 'pregunta1' )"  ><b>Campo requerido</b></p>
                        <p :class="isMobile ? 'mt-4' : 'mt-5'" >¿Cuáles son las fortalezas que tenemos para alcanzar nuestras metas?</p>
                          <b-form-input type="text" style="border-radius: 10px; width: 60%; margin-top:-10px;" v-model="pregunta3"/>
                          <p class="text-danger" v-if="errors.includes( 'pregunta2' )"  ><b>Campo requerido</b></p>
                          <b-row class="justify-content-center" style="margin-top:-5px;"> 
                             <b-col cols="12" md="6">
                              <p class="mt-5"><b>Participación de las marcas</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila12' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila12 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila12(1)">
                                    <img :src="'encuesta/2' + img2fila12 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila12(2)">
                                    <img :src="'encuesta/3' + img3fila12 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila12(3)">
                                    <img :src="'encuesta/4' + img4fila12 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila12(4)">
                                    <img :src="'encuesta/5' + img5fila12 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila12(5)"> 
                             </b-col>
                             <b-col cols="12" md="6">
                              <p class="mt-5"><b>Conferencia de Faitelson & Schutz</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila13' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila13 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila13(1)">
                                    <img :src="'encuesta/2' + img2fila13 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila13(2)">
                                    <img :src="'encuesta/3' + img3fila13 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila13(3)">
                                    <img :src="'encuesta/4' + img4fila13 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila13(4)">
                                    <img :src="'encuesta/5' + img5fila13 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila13(5)"> 
                             </b-col>  
                          </b-row>
                          <p :class="isMobile ? 'mt-4' : 'mt-5'" >¿A quién te gustaría escuchar en la próxima edición?</p>
                          <b-form-input type="text" style="border-radius: 10px; width: 60%; margin-top:-10px;" v-model="pregunta6"/>
                          <p class="text-danger" v-if="errors.includes( 'pregunta2' )"  ><b>Campo requerido</b></p>
                          <b-row class="justify-content-center" style="margin-top:-5px;"> 
                             <b-col cols="12" md="6">
                              <p class="mt-5"><b>Cena - show de Fuego y Agua</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila7' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila7 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila7(1)">
                                    <img :src="'encuesta/2' + img2fila7 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila7(2)">
                                    <img :src="'encuesta/3' + img3fila7 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila7(3)">
                                    <img :src="'encuesta/4' + img4fila7 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila7(4)">
                                    <img :src="'encuesta/5' + img5fila7 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila7(5)"> 
                             </b-col>
                             <b-col cols="12" md="6">
                              <p class="mt-5"><b>Actividad de Integración</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila8' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila8 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila8(1)">
                                    <img :src="'encuesta/2' + img2fila8 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila8(2)">
                                    <img :src="'encuesta/3' + img3fila8 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila8(3)">
                                    <img :src="'encuesta/4' + img4fila8 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila8(4)">
                                    <img :src="'encuesta/5' + img5fila8 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila8(5)"> 
                             </b-col> 
                             <b-col cols="12" md="6">
                              <p class="mt-5"><b>Cena Clausura</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila9' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(1)">
                                    <img :src="'encuesta/2' + img2fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(2)">
                                    <img :src="'encuesta/3' + img3fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(3)">
                                    <img :src="'encuesta/4' + img4fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(4)">
                                    <img :src="'encuesta/5' + img5fila9 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila9(5)"> 
                             </b-col>
                             <b-col cols="12" md="6">
                              <p class="mt-5"><b>Stand up Daniel Sosa</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila10' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(1)">
                                    <img :src="'encuesta/2' + img2fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(2)">
                                    <img :src="'encuesta/3' + img3fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(3)">
                                    <img :src="'encuesta/4' + img4fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(4)">
                                    <img :src="'encuesta/5' + img5fila10 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila10(5)"> 
                             </b-col> 
                          </b-row>
                          <p :class="isMobile ? 'mt-4' : 'mt-5'" >¿Cuál sería tu sugerencia para futuras Convenciones?</p>
                          <b-form-input type="text" style="border-radius: 10px; width: 60%; margin-top:-10px;" v-model="pregunta4"/>
                          <p class="text-danger" v-if="errors.includes( 'pregunta2' )"  ><b>Campo requerido</b></p>
                          <b-row style="margin-top:-5px;"> 
                             <b-col cols="12" md="6">
                              <p class="mt-5"><b>After Party</b></p>
                                  <p class="text-danger" v-if="errors.includes( 'fila11' )"  ><b>Campo requerido</b></p>
                                    <img :src="'encuesta/1' + img1fila11 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila11(1)">
                                    <img :src="'encuesta/2' + img2fila11 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila11(2)">
                                    <img :src="'encuesta/3' + img3fila11 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila11(3)">
                                    <img :src="'encuesta/4' + img4fila11 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila11(4)">
                                    <img :src="'encuesta/5' + img5fila11 + '.png'" class="rounded ml-3" alt="Image" width="33"  id="camp" @click="pulsaFila11(5)"> 
                             </b-col> 
                          </b-row>
                          <p :class="isMobile ? 'mt-4' : 'mt-5'" >¿Tienes alguna sugerencia o comentario que nos quieras compartir?</p>
                          <b-form-input type="text" style="border-radius: 10px; width: 60%; margin-top:-10px;" v-model="pregunta5"/>
                          <p class="text-danger" v-if="errors.includes( 'pregunta2' )"  ><b>Campo requerido</b></p>
                    </b-col>
                </b-row> 
                <br>
            </div> 
            <br>
            <center>
               <p style="color: #fff;">Muchas gracias por tu opinión y participación. <br> ¡Nos vemos en la próxima!</p>
                <b-button  type="submit" variant="primary" :disabled="loader == true" style="background: transparent; border-color: transparent;"> 
                  <center> <img src="elementos/enviarr.png" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:54%; cursor:pointer;'">   <b-spinner v-if="loader" small></b-spinner> </center> 
              </b-button>
            
            </center>
          </b-form>
         </b-col>
         <b-col cols="11"  sm="11"  md="11" lg="6" xl="6" v-if="video == 1"> 
          <center>
            <br><br><br>
            <video :width="isMobile ? 300 : 700" :height="isMobile ? 250 : 330" controls autoplay>
              <source src="encuesta/movistar_cabos.mp4" type="video/mp4"> 
            </video>
            <br><br>
            <b-row class="justify-content-center mt-5" > 
                <b-col cols="10" md="5" >
                  <center><img src="elementos/regresar.png" class="rounded" alt="Image" :style="isMobile ? 'width:100%;' : 'width:80%; cursor:pointer;'"  @click="video = 0"> </center>
                </b-col> 
                <b-col cols="10" md="5" >
                  <br v-if="isMobile">
                  <center><img src="elementos/descargar_video.png" class="rounded" alt="Image" :style="isMobile ? 'width:100%;' : 'width:80%; cursor:pointer;'" @click="descargaVideo()"> </center> 
                </b-col>  
            </b-row>  
          </center>
         </b-col>
       </b-row>    
              <br><br><br> 
  </div>
</template>

<script>
  // import Multiselect from 'vue-multiselect'
// import $ from "jquery";
// import InitController from "@/utils/InitController";  
import Swal from 'sweetalert2'
// Import Bootstrap and BootstrapVue CSS files (order is important) 
export default {
  name: 'encuesta',
  //  components: { Multiselect },
  data(){
    return{
      login:0,
      isMobile: this.$mobile(),
      convencion:0,
      img1fila1:"f",
      img2fila1:"f",
      img3fila1:"f",
      img4fila1:"f",
      img5fila1:"f", 
      img1fila2:"f",
      img2fila2:"f",
      img3fila2:"f",
      img4fila2:"f",
      img5fila2:"f",  
      img1fila3:"f",
      img2fila3:"f",
      img3fila3:"f",
      img4fila3:"f",
      img5fila3:"f", 
      img1fila4:"f",
      img2fila4:"f",
      img3fila4:"f",
      img4fila4:"f",
      img5fila4:"f", 
      img1fila5:"f",
      img2fila5:"f",
      img3fila5:"f",
      img4fila5:"f",
      img5fila5:"f", 
      img1fila6:"f",
      img2fila6:"f",
      img3fila6:"f",
      img4fila6:"f",
      img5fila6:"f", 
      img1fila7:"f",
      img2fila7:"f",
      img3fila7:"f",
      img4fila7:"f",
      img5fila7:"f", 
      img1fila8:"f",
      img2fila8:"f",
      img3fila8:"f",
      img4fila8:"f",
      img5fila8:"f", 
      img1fila9:"f",
      img2fila9:"f",
      img3fila9:"f",
      img4fila9:"f",
      img5fila9:"f", 
      img1fila10:"f",
      img2fila10:"f",
      img3fila10:"f",
      img4fila10:"f",
      img5fila10:"f",  
      img1fila11:"f",
      img2fila11:"f",
      img3fila11:"f",
      img4fila11:"f",
      img5fila11:"f",  
      img1fila12:"f",
      img2fila12:"f",
      img3fila12:"f",
      img4fila12:"f",
      img5fila12:"f",
      img1fila13:"f",
      img2fila13:"f",
      img3fila13:"f",
      img4fila13:"f",
      img5fila13:"f",    
      pregunta1:"",
      pregunta2:"",
      pregunta3:"",
      pregunta4:"",
      pregunta5:"",
      pregunta6:"",
      errors:[],
      loader:false,
      vertical:0,
      nombre:"",
      canal:0,
      descarga:0,
      video:0,
      fila1:0,
      fila2:0,
      fila3:0,
      fila4:0,
      fila5:0,
      fila6:0,
      fila7:0,
      fila8:0,
      fila9:0,
      fila10:0,
      fila11:0,
      fila12:0,
      fila13:0,
    }
  }, 
  methods:{
    cambiar(){ 
        this.convencion = 1;
        if (this.isMobile)
        {
          this.$bus.$emit("cambi_fo5", true)
        } 
    },
    loginAcces(){
      if (!this.nombre) {
        Swal.fire({
          icon: "error",
          title: "Nombre requerido"
        });
      } else {
        this.loader = true;
         var url= "auth/loginencuesta?nombre=" + this.nombre;
          this.$api.get(url).then(
              ({data}) => {
                  console.log(data); 
                  this.login = data.valida;
                  this.loader = false;
              }
          );
      }
    },
    store(e) {
       e.preventDefault();
        this.errors = [];
        let model = this.prepareModel()
        
       if(!this.nombre){
            this.errors.push('nombre');     
        }if(!this.canal){
            this.errors.push('canal');     
        } if(this.errors.length == 0){ 
            this.loader = true;
            let request = this.$api.post("auth/convencion", model);
            request.then(
                response => {   
                    console.log(response)      
                    this.loader = false;    
                    
                    if (this.isMobile)
                    {
                     this.$bus.$emit("cambi_fo2", true)
                    } 

                    Swal.fire({
                      position: "top-center",
                      icon: "success",
                      title: "Gracias por compartir tu opinión.",
                      showConfirmButton: false,
                      timer: 2000
                    });
                    // Swal.fire(
                    // "Listo",
                    // "Gracias por compartir tu opinión.",
                    // 'success'
                    // )     

                    this.cerrar();

                },
                error => {
                    this.loader = false;
                    console.log(error);
                }
            )
        }
    },
    prepareModel() {
        let model = new FormData();
        model.append("fila1", this.fila1) 
        model.append("fila2", this.fila2)
        model.append("fila3", this.fila3)
        model.append("fila4", this.fila4)
        model.append("fila5", this.fila5)
        model.append("fila6", this.fila6)
        model.append("fila7", this.fila7)
        model.append("fila8", this.fila8)
        model.append("fila9", this.fila9)
        model.append("fila10", this.fila10) 
        model.append("fila11", this.fila11) 
        model.append("fila12", this.fila12) 
        model.append("fila13", this.fila13) 
        model.append("pregunta1", this.pregunta1)
        model.append("pregunta2", this.pregunta2)
        model.append("pregunta3", this.pregunta3)
        model.append("pregunta4", this.pregunta4)
        model.append("pregunta5", this.pregunta5)
        model.append("pregunta6", this.pregunta6)
        model.append("nombre", this.nombre)
        model.append("canal", this.canal)

        return model;
    },
    cerrar(){
      this.convencion = 0;
      this.img1fila1 = "m";
      this.img2fila1 = "m";
      this.img3fila1 = "m";
      this.img4fila1 = "m";
      this.img5fila1 = "m"; 
      this.img1fila2 = "m";
      this.img2fila2 = "m";
      this.img3fila2 = "m";
      this.img4fila2 = "m";
      this.img5fila2 = "m";  
      this.img1fila3 = "m";
      this.img2fila3 = "m";
      this.img3fila3 = "m";
      this.img4fila3 = "m";
      this.img5fila3 = "m"; 
      this.img1fila4 = "m";
      this.img2fila4 = "m";
      this.img3fila4 = "m";
      this.img4fila4 = "m";
      this.img5fila4 = "m"; 
      this.img1fila5 = "m";
      this.img2fila5 = "m";
      this.img3fila5 = "m";
      this.img4fila5 = "m";
      this.img5fila5 = "m"; 
      this.img1fila6 = "m";
      this.img2fila6 = "m";
      this.img3fila6 = "m";
      this.img4fila6 = "m";
      this.img5fila6 = "m"; 
      this.img1fila7 = "m";
      this.img2fila7 = "m";
      this.img3fila7 = "m";
      this.img4fila7 = "m";
      this.img5fila7 = "m"; 
      this.img1fila8 = "m";
      this.img2fila8 = "m";
      this.img3fila8 = "m";
      this.img4fila8 = "m";
      this.img5fila8 = "m"; 
      this.img1fila9 = "m";
      this.img2fila9 = "m";
      this.img3fila9 = "m";
      this.img4fila9 = "m";
      this.img5fila9 = "m"; 
      this.img1fila10 = "m";
      this.img2fila10 = "m";
      this.img3fila10 = "m";
      this.img4fila10 = "m";
      this.img5fila10 = "m"; 
      this.img1fila11 = "m";
      this.img2fila11 = "m";
      this.img3fila11 = "m";
      this.img4fila11 = "m";
      this.img5fila11 = "m"; 
      this.img1fila12 = "m";
      this.img2fila12 = "m";
      this.img3fila12 = "m";
      this.img4fila12 = "m";
      this.img5fila12 = "m"; 
      this.img1fila13 = "m";
      this.img2fila13 = "m";
      this.img3fila13 = "m";
      this.img4fila13 = "m";
      this.img5fila13 = "m"; 
      this.pregunta1 = "";
      this.pregunta2 = "";    
      this.pregunta3 = "";
      this.pregunta4 = "";
      this.pregunta5 = "";
      this.pregunta6 = "";
      this.nombre = "";
      this.canal = 0; 
    },
    descargaFoto(){
      var source = 'https://convencioncomercial.com.mx/encuesta/grupal.jpg';

      var a = document.createElement('a');

      a.download = "Foto_Convención_2025";
      a.target = '_blank';
      a.href= source;

      a.click(); 
    },
    descargaVideo(){
      var source = 'https://convencioncomercial.com.mx/encuesta/movistar_cabos.mp4';

      var a = document.createElement('a');

      a.download = "Video_Convención_2024";
      a.target = '_blank';
      a.href= source;

      a.click(); 
    },
    verVideo(){
     this.video = 1;
    },
    pulsaFila1(id){
      this.fila1 = id;
      this.img1fila1 = "f";
      this.img2fila1 = "f";
      this.img3fila1 = "f";
      this.img4fila1 = "f";
      this.img5fila1 = "f"; 
      switch (id) {
        case 1:
          this.img1fila1 = 'b'; 
        break; 
        case 2:
          this.img1fila1 = 'b';
          this.img2fila1 = 'b'; 
        break; 
        case 3:
          this.img1fila1 = 'b';
          this.img2fila1 = 'b';
          this.img3fila1 = 'b'; 
        break; 
        case 4:
          this.img1fila1 = 'b';
          this.img2fila1 = 'b';
          this.img3fila1 = 'b'; 
          this.img4fila1 = 'b'; 
        break; 
        case 5:
          this.img1fila1 = 'b';
          this.img2fila1 = 'b';
          this.img3fila1 = 'b'; 
          this.img4fila1 = 'b'; 
          this.img5fila1 = 'b'; 
        break;   
      }
    },
    pulsaFila2(id){
      this.fila2 = id;
      this.img1fila2 = "f";
      this.img2fila2 = "f";
      this.img3fila2 = "f";
      this.img4fila2 = "f";
      this.img5fila2 = "f"; 
      switch (id) {
        case 1:
          this.img1fila2 = 'b'; 
        break; 
        case 2:
          this.img1fila2 = 'b';
          this.img2fila2 = 'b'; 
        break; 
        case 3:
          this.img1fila2 = 'b';
          this.img2fila2 = 'b';
          this.img3fila2 = 'b'; 
        break; 
        case 4:
          this.img1fila2 = 'b';
          this.img2fila2 = 'b';
          this.img3fila2 = 'b'; 
          this.img4fila2 = 'b'; 
        break; 
        case 5:
          this.img1fila2 = 'b';
          this.img2fila2 = 'b';
          this.img3fila2 = 'b'; 
          this.img4fila2 = 'b'; 
          this.img5fila2 = 'b'; 
        break;   
      }
    },
    pulsaFila3(id){
      this.fila3 = id;
      this.img1fila3 = "f";
      this.img2fila3 = "f";
      this.img3fila3 = "f";
      this.img4fila3 = "f";
      this.img5fila3 = "f"; 
      switch (id) {
        case 1:
          this.img1fila3 = 'b'; 
        break; 
        case 2:
          this.img1fila3 = 'b';
          this.img2fila3 = 'b'; 
        break; 
        case 3:
          this.img1fila3 = 'b';
          this.img2fila3 = 'b';
          this.img3fila3 = 'b'; 
        break; 
        case 4:
          this.img1fila3 = 'b';
          this.img2fila3 = 'b';
          this.img3fila3 = 'b'; 
          this.img4fila3 = 'b'; 
        break; 
        case 5:
          this.img1fila3 = 'b';
          this.img2fila3 = 'b';
          this.img3fila3 = 'b'; 
          this.img4fila3 = 'b'; 
          this.img5fila3 = 'b'; 
        break;   
      }
    },
    pulsaFila4(id){
      this.fila4 = id;
      this.img1fila4 = "f";
      this.img2fila4 = "f";
      this.img3fila4 = "f";
      this.img4fila4 = "f";
      this.img5fila4 = "f"; 
      switch (id) {
        case 1:
          this.img1fila4 = 'b'; 
        break; 
        case 2:
          this.img1fila4 = 'b';
          this.img2fila4 = 'b'; 
        break; 
        case 3:
          this.img1fila4 = 'b';
          this.img2fila4 = 'b';
          this.img3fila4 = 'b'; 
        break; 
        case 4:
          this.img1fila4 = 'b';
          this.img2fila4 = 'b';
          this.img3fila4 = 'b'; 
          this.img4fila4 = 'b'; 
        break; 
        case 5:
          this.img1fila4 = 'b';
          this.img2fila4 = 'b';
          this.img3fila4 = 'b'; 
          this.img4fila4 = 'b'; 
          this.img5fila4 = 'b'; 
        break;   
      }
    },
    pulsaFila5(id){
      this.fila5 = id;
      this.img1fila5 = "f";
      this.img2fila5 = "f";
      this.img3fila5 = "f";
      this.img4fila5 = "f";
      this.img5fila5 = "f"; 
      switch (id) {
        case 1:
          this.img1fila5 = 'b'; 
        break; 
        case 2:
          this.img1fila5 = 'b';
          this.img2fila5 = 'b'; 
        break; 
        case 3:
          this.img1fila5 = 'b';
          this.img2fila5 = 'b';
          this.img3fila5 = 'b'; 
        break; 
        case 4:
          this.img1fila5 = 'b';
          this.img2fila5 = 'b';
          this.img3fila5 = 'b'; 
          this.img4fila5 = 'b'; 
        break; 
        case 5:
          this.img1fila5 = 'b';
          this.img2fila5 = 'b';
          this.img3fila5 = 'b'; 
          this.img4fila5 = 'b'; 
          this.img5fila5 = 'b'; 
        break;   
      }
    },
    pulsaFila6(id){
      this.fila6 = id;
      this.img1fila6 = "f";
      this.img2fila6 = "f";
      this.img3fila6 = "f";
      this.img4fila6 = "f";
      this.img5fila6 = "f"; 
      switch (id) {
        case 1:
          this.img1fila6 = 'b'; 
        break; 
        case 2:
          this.img1fila6 = 'b';
          this.img2fila6 = 'b'; 
        break; 
        case 3:
          this.img1fila6 = 'b';
          this.img2fila6 = 'b';
          this.img3fila6 = 'b'; 
        break; 
        case 4:
          this.img1fila6 = 'b';
          this.img2fila6 = 'b';
          this.img3fila6 = 'b'; 
          this.img4fila6 = 'b'; 
        break; 
        case 5:
          this.img1fila6 = 'b';
          this.img2fila6 = 'b';
          this.img3fila6 = 'b'; 
          this.img4fila6 = 'b'; 
          this.img5fila6 = 'b'; 
        break;   
      }
    },
    pulsaFila7(id){
      this.fila7 = id;
      this.img1fila7 = "f";
      this.img2fila7 = "f";
      this.img3fila7 = "f";
      this.img4fila7 = "f";
      this.img5fila7 = "f"; 
      switch (id) {
        case 1:
          this.img1fila7 = 'b'; 
        break; 
        case 2:
          this.img1fila7 = 'b';
          this.img2fila7 = 'b'; 
        break; 
        case 3:
          this.img1fila7 = 'b';
          this.img2fila7 = 'b';
          this.img3fila7 = 'b'; 
        break; 
        case 4:
          this.img1fila7 = 'b';
          this.img2fila7 = 'b';
          this.img3fila7 = 'b'; 
          this.img4fila7 = 'b'; 
        break; 
        case 5:
          this.img1fila7 = 'b';
          this.img2fila7 = 'b';
          this.img3fila7 = 'b'; 
          this.img4fila7 = 'b'; 
          this.img5fila7 = 'b'; 
        break;   
      }
    },
    pulsaFila8(id){
      this.fila8 = id;
      this.img1fila8 = "f";
      this.img2fila8 = "f";
      this.img3fila8 = "f";
      this.img4fila8 = "f";
      this.img5fila8 = "f"; 
      switch (id) {
        case 1:
          this.img1fila8 = 'b'; 
        break; 
        case 2:
          this.img1fila8 = 'b';
          this.img2fila8 = 'b'; 
        break; 
        case 3:
          this.img1fila8 = 'b';
          this.img2fila8 = 'b';
          this.img3fila8 = 'b'; 
        break; 
        case 4:
          this.img1fila8 = 'b';
          this.img2fila8 = 'b';
          this.img3fila8 = 'b'; 
          this.img4fila8 = 'b'; 
        break; 
        case 5:
          this.img1fila8 = 'b';
          this.img2fila8 = 'b';
          this.img3fila8 = 'b'; 
          this.img4fila8 = 'b'; 
          this.img5fila8 = 'b'; 
        break;   
      }
    },
    pulsaFila9(id){
      this.fila9 = id;
      this.img1fila9 = "f";
      this.img2fila9 = "f";
      this.img3fila9 = "f";
      this.img4fila9 = "f";
      this.img5fila9 = "f"; 
      switch (id) {
        case 1:
          this.img1fila9 = 'b'; 
        break; 
        case 2:
          this.img1fila9 = 'b';
          this.img2fila9 = 'b'; 
        break; 
        case 3:
          this.img1fila9 = 'b';
          this.img2fila9 = 'b';
          this.img3fila9 = 'b'; 
        break; 
        case 4:
          this.img1fila9 = 'b';
          this.img2fila9 = 'b';
          this.img3fila9 = 'b'; 
          this.img4fila9 = 'b'; 
        break; 
        case 5:
          this.img1fila9 = 'b';
          this.img2fila9 = 'b';
          this.img3fila9 = 'b'; 
          this.img4fila9 = 'b'; 
          this.img5fila9 = 'b'; 
        break;   
      }
    },
    pulsaFila10(id){
      this.fila10 = id;
      this.img1fila10 = "f";
      this.img2fila10 = "f";
      this.img3fila10 = "f";
      this.img4fila10 = "f";
      this.img5fila10 = "f"; 
      switch (id) {
        case 1:
          this.img1fila10 = 'b'; 
        break; 
        case 2:
          this.img1fila10 = 'b';
          this.img2fila10 = 'b'; 
        break; 
        case 3:
          this.img1fila10 = 'b';
          this.img2fila10 = 'b';
          this.img3fila10 = 'b'; 
        break; 
        case 4:
          this.img1fila10 = 'b';
          this.img2fila10 = 'b';
          this.img3fila10 = 'b'; 
          this.img4fila10 = 'b'; 
        break; 
        case 5:
          this.img1fila10 = 'b';
          this.img2fila10 = 'b';
          this.img3fila10 = 'b'; 
          this.img4fila10 = 'b'; 
          this.img5fila10 = 'b'; 
        break;   
      }
    },
    pulsaFila11(id){
      this.fila11 = id;
      this.img1fila11 = "f";
      this.img2fila11 = "f";
      this.img3fila11 = "f";
      this.img4fila11 = "f";
      this.img5fila11 = "f"; 
      switch (id) {
        case 1:
          this.img1fila11 = 'b'; 
        break; 
        case 2:
          this.img1fila11 = 'b';
          this.img2fila11 = 'b'; 
        break; 
        case 3:
          this.img1fila11 = 'b';
          this.img2fila11 = 'b';
          this.img3fila11 = 'b'; 
        break; 
        case 4:
          this.img1fila11 = 'b';
          this.img2fila11 = 'b';
          this.img3fila11 = 'b'; 
          this.img4fila11 = 'b'; 
        break; 
        case 5:
          this.img1fila11 = 'b';
          this.img2fila11 = 'b';
          this.img3fila11 = 'b'; 
          this.img4fila11 = 'b'; 
          this.img5fila11 = 'b'; 
        break;   
      }
    },
    pulsaFila12(id){
      this.fila12 = id;
      this.img1fila12 = "f";
      this.img2fila12 = "f";
      this.img3fila12 = "f";
      this.img4fila12 = "f";
      this.img5fila12 = "f"; 
      switch (id) {
        case 1:
          this.img1fila12 = 'b'; 
        break; 
        case 2:
          this.img1fila12 = 'b';
          this.img2fila12 = 'b'; 
        break; 
        case 3:
          this.img1fila12 = 'b';
          this.img2fila12 = 'b';
          this.img3fila12 = 'b'; 
        break; 
        case 4:
          this.img1fila12 = 'b';
          this.img2fila12 = 'b';
          this.img3fila12 = 'b'; 
          this.img4fila12 = 'b'; 
        break; 
        case 5:
          this.img1fila12 = 'b';
          this.img2fila12 = 'b';
          this.img3fila12 = 'b'; 
          this.img4fila12 = 'b'; 
          this.img5fila12 = 'b'; 
        break;   
      }
    },
    pulsaFila13(id){
      this.fila13 = id;
      this.img1fila13 = "f";
      this.img2fila13 = "f";
      this.img3fila13 = "f";
      this.img4fila13 = "f";
      this.img5fila13 = "f"; 
      switch (id) {
        case 1:
          this.img1fila13 = 'b'; 
        break; 
        case 2:
          this.img1fila13 = 'b';
          this.img2fila13 = 'b'; 
        break; 
        case 3:
          this.img1fila13 = 'b';
          this.img2fila13 = 'b';
          this.img3fila13 = 'b'; 
        break; 
        case 4:
          this.img1fila13 = 'b';
          this.img2fila13 = 'b';
          this.img3fila13 = 'b'; 
          this.img4fila13 = 'b'; 
        break; 
        case 5:
          this.img1fila13 = 'b';
          this.img2fila13 = 'b';
          this.img3fila13 = 'b'; 
          this.img4fila13 = 'b'; 
          this.img5fila13 = 'b'; 
        break;   
      }
    }
  },
  mounted(){
    //  window.location.href = "https://convencioncomercial.com.mx/encuesta/encuesta.jpg";   
    this.$bus.$emit("cambia_embajador", true)

    if(!this.isMobile)
      {
          this.$bus.$emit("fondo_capa", "fondoSolo")
      } 

   if (this.isMobile)
    {
      this.$bus.$emit("cambi_fo22", true)
    } 

    
    if (window.orientation == '90'  && this.isMobile  || window.orientation == '-90'  && this.isMobile )
     {
       this.vertical = 1;
     } 
  }
}
</script>  
<style>
 .form-check-input[type=radio] {
    width: 1.2rem;
    height: 1.2rem;
    border-color: black;
 }

 #camp:hover 
  {
    opacity: 1;
    transform: scale(1.1);
    transition: all 250ms ease;
    color: rgb(33, 189, 236);
    border-radius: 25px;
    cursor: pointer;
  }

</style>